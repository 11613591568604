import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Accueil',
    component: () => import('@/views/Francais.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/fr',
    name: 'Francais',
    component: () => import('@/views/Francais.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/en',
    name: 'English',
    component: () => import('@/views/English.vue'),
    meta: { requiresAuth: false },
  },
]


const router = new VueRouter({
  base: '/',
  routes
})

router.beforeResolve ((to, from, next) => {
  console.log("FIRST",to.fullPath)
  if(to.fullPath === "/") {
    console.log(to.fullPath)
    console.log("JJJJ")
    let lang = "fr"
    if(!localStorage.getItem("lesbouqgat_lang")) {
      localStorage.setItem("lesbouqgat_lang", "fr")
    }
    else {
      lang = localStorage.getItem("lesbouqgat_lang")
    }

    if(lang === "fr") {
      console.log("fr")
      next('/fr')
    }
    else {
      console.log("en")
      next('/en')
    }
  }
  next()
})



export default router
