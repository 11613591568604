<template>
  <div id="app">
    <div class="content-view">
      <router-view />
    </div>
  </div>
</template>
<script>
export default {

}
</script>

<style lang="scss">
#app {


}
</style>
